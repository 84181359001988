import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'antd';
import { Button, Icon } from '@cognite/cogs.js';
import { itemSelector as fileItemSelector, retrieve } from 'modules/files';
import {
  list as listAnnotations,
  selectAnnotations,
} from 'modules/annotations';
import { AnnotatedPnIDPreview } from 'components/PnIDPreview/AnnotatedPnIDPreview';
import { startConvertFileToSvgJob } from 'modules/fileContextualization/uploadJobs';
import { RootState } from 'reducers';
import { canEditFiles } from 'utils/PermissionUtils';
import { ResourceSidebar } from 'containers/ResourceSidebar';

const Wrapper = styled.div`
  display: flex;
  height: calc(100vh - 180px);
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  padding: 20px 24px;
  box-shadow: 0px 0px 6px #cdcdcd;
  border-radius: 12px;
  flex-direction: row;
  align-items: center;
  z-index: 1;
  margin-top: 40px;
  background: #fff;
  button {
    margin-right: 26px;
  }
  h1 {
    margin-bottom: 0px;
    flex: 1;
  }
`;

export default function PnIDParsingFilePreview() {
  const {
    fileId,
    tenant,
    filesDataKitId,
    assetsDataKitId,
    optionsId,
  } = useParams<{
    fileId: string;
    tenant: string;
    filesDataKitId: string;
    assetsDataKitId: string;
    optionsId: string;
  }>();
  const dispatch = useDispatch();
  const history = useHistory();

  const fileIdNumber = parseInt(fileId, 10);

  const file = useSelector(fileItemSelector)(fileIdNumber);

  const annotations = useSelector(selectAnnotations)(fileIdNumber);
  const uploadJob = useSelector(
    (state: RootState) => state.fileContextualization.uploadJobs[fileIdNumber]
  );

  useEffect(() => {
    dispatch(retrieve([{ id: fileIdNumber }]));
  }, [dispatch, fileIdNumber]);

  useEffect(() => {
    if (file) {
      dispatch(listAnnotations(file, false, true));
    }
  }, [dispatch, file]);
  return (
    <Wrapper>
      <Header>
        <Button
          shape="round"
          icon="ArrowLeft"
          onClick={() =>
            history.push(
              `/${tenant}/pnid_parsing/${filesDataKitId}/${assetsDataKitId}/${optionsId}`
            )
          }
        >
          Back
        </Button>
        <h1>{file ? file.name : 'Loading...'}</h1>
        <Tooltip
          placement="left"
          arrowPointAtCenter
          title="This will create or update an interactive SVG linked to the assets for this file."
        >
          <Icon type="Help" style={{ marginRight: '24px', fontSize: '18px' }} />
        </Tooltip>
        <Button
          shape="round"
          type="primary"
          icon="Upload"
          style={{ marginRight: '0px' }}
          loading={uploadJob && !uploadJob.jobDone}
          onClick={() => {
            if (annotations && canEditFiles(true)) {
              dispatch(startConvertFileToSvgJob(fileIdNumber, annotations));
            }
          }}
        >
          Deploy to CDF
        </Button>
      </Header>
      <AnnotatedPnIDPreview
        fileId={fileIdNumber}
        onFileClicked={newFile => history.push(`${newFile.id}`)}
      />
      <ResourceSidebar />
    </Wrapper>
  );
}
