import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import SearchPage from 'containers/SearchPage';
import AssetResourceMatching from './AssetResourceMatching';
import EntityMatcherOptions from './EntityMatcherOptions';

function EntityMatchingRoutes() {
  const match = useRouteMatch();
  return (
    <>
      <Switch>
        <Route
          exact
          path={match.url}
          render={() => (
            <SearchPage
              type="timeseries"
              availableTypes={['timeseries', 'sequences', 'files']}
            />
          )}
        />
        <Route
          exact
          path={`${match.url}/:resourceDataKitId`}
          render={() => <SearchPage type="assets" />}
        />
        <Route
          exact
          path={`${match.url}/:resourceDataKitId/:assetsDataKitId`}
          component={EntityMatcherOptions}
        />
        <Route
          exact
          path={`${match.url}/:resourceDataKitId/:assetsDataKitId/:modelId/:predictId/:ruleId`}
          component={AssetResourceMatching}
        />
        <Route
          exact
          path={`${match.url}/:resourceDataKitId/:assetsDataKitId/:modelId/:predictId/:ruleId/:changed`}
          component={AssetResourceMatching}
        />
      </Switch>
    </>
  );
}

function breadcrumbs(mountedAt: string) {
  return () => ({
    [`${mountedAt}`]: ['Match Resource Types', 'Choose Resources'],
    [`${mountedAt}//:resourceDataKitId`]: ['Assets Selection'],
    [`${mountedAt}/:resourceDataKitId/:assetsDataKitId`]: [
      'Model Specs',
      'Match Resources',
    ],
  });
}

EntityMatchingRoutes.breadcrumbs = breadcrumbs;

export default EntityMatchingRoutes;
