import { combineReducers } from 'redux';
import functions from 'modules/functions/';
import app from 'modules/app';
import assets from 'modules/assets';
import dataSets from 'modules/datasets';
import login from 'modules/login';
import timeseries from 'modules/timeseries';
import events from 'modules/events';
import files from 'modules/files';
import contextualization from 'modules/contextualization/';
import fileContextualization from 'modules/fileContextualization';
import selection from 'modules/selection';
import annotations from 'modules/annotations';
import sequences from 'modules/sequences';

const createRootReducer = () =>
  combineReducers({
    annotations,
    app,
    assets,
    dataSets,
    functions,
    login,
    timeseries,
    events,
    files,
    contextualization,
    fileContextualization,
    selection,
    sequences,
  });

export type RootState = ReturnType<ReturnType<typeof createRootReducer>>;

export default createRootReducer;
