import React, { useState, useEffect } from 'react';
import { uniq } from 'lodash';
import { useDebouncedCallback } from 'use-debounce';
import moment from 'moment';
import { FilesMetadata } from '@cognite/sdk';
import { ResourceType } from 'modules/sdk-builder/types';
import { doSearch, Filter } from 'modules/search';

import {
  Col,
  Empty,
  Row,
  Select,
  Table,
  Tooltip,
  Typography,
  message,
} from 'antd';
import { Button, Icon } from '@cognite/cogs.js';
import { useSelector, useDispatch } from 'react-redux';
import { usePrevious } from 'hooks/CustomHooks';
import { PendingResourceSelection, create } from 'modules/selection';
import { useRouteMatch, useHistory } from 'react-router-dom';
import StickyBottomRow from 'components/StickyBottomRow';
import { FileHoverPreview } from 'containers/HoverPreview';
import { Popover } from 'components/Common';
import { ResourceSidebar } from 'containers/ResourceSidebar';
import AssetSearchBar from './AssetSearchBar';
import FileSearchBar from './FileSearchBar';
import TimeseriesSearchBar from './TimeseriesSearchBar';
import SequencesSearchBar from './SequencesSearchBar';
import EventSearchBar from './EventSearchBar';
import { searchCountSelector, searchItemSelector } from './selectors';

const getColumns = (type: ResourceType) => {
  switch (type) {
    default: {
      return [
        {
          title: 'Name',
          key: 'name',
          dataIndex: 'name',
          render: (name: string, item: any) => {
            switch (type) {
              case 'assets':
                return name;
              case 'files':
                return (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Popover
                      style={{ marginRight: '6px', display: 'flex' }}
                      content={
                        <FileHoverPreview file={item as FilesMetadata} />
                      }
                    >
                      <Icon type="Document" />
                    </Popover>
                    <span>{name}</span>
                  </div>
                );
              case 'timeseries':
                return name;
              default:
                return name;
            }
          },
        },
        {
          title: 'Description',
          key: 'description',
          dataIndex: 'description',
        },
        {
          title: 'Last Modified',
          key: 'last-modified',
          render: (item: FilesMetadata) => {
            return moment(item.lastUpdatedTime).format('YYYY-MM-DD hh:mm');
          },
        },
      ];
    }
  }
};

const getSearchBar = (
  type: ResourceType,
  filter: Filter,
  updateFilter: (f: Filter) => void
) => {
  switch (type) {
    case 'files':
      return <FileSearchBar filter={filter} updateFilter={updateFilter} />;
    case 'assets':
      return <AssetSearchBar filter={filter} updateFilter={updateFilter} />;
    case 'timeseries':
      return (
        <TimeseriesSearchBar filter={filter} updateFilter={updateFilter} />
      );
    case 'sequences':
      return <SequencesSearchBar filter={filter} updateFilter={updateFilter} />;
    case 'events':
      return <EventSearchBar filter={filter} updateFilter={updateFilter} />;
    default:
      throw new Error(`type '${type}' not supported`);
  }
};

type Props = {
  type: ResourceType;
  defaultFilters?: { [key in ResourceType]?: Filter };
  availableTypes?: ResourceType[];
  noDataText?: string;
  onNextClicked?: (
    selectionSize: number,
    selection: PendingResourceSelection
  ) => boolean;
};

const DEFAULT_FILTERS = {};
const EMPTY_FILTER: Filter = {
  search: undefined,
  filter: {},
};

export default function SearchPage({
  type,
  defaultFilters = DEFAULT_FILTERS,
  onNextClicked = () => true,
  availableTypes = [],
  noDataText,
}: Props) {
  const history = useHistory();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [currentType, setCurrentType] = useState<ResourceType>(type);
  const [isSelectAll, setSelectAll] = useState(false);
  const [canSelectAll, setCanSelectAll] = useState(true);
  const [selectedRowKeys, setSelectedRowKey] = useState([] as number[]);
  // `filter` is used for rendering the selected filter,
  // `delayedFilter` is used for API-calls and store lookup.
  const [filter, setFilter] = useState<Filter>(
    defaultFilters[currentType] || EMPTY_FILTER
  );
  const [delayedFilter, setDelayedFilter] = useState<Filter>(filter);
  const [debouncedSetFilter] = useDebouncedCallback(setDelayedFilter, 300);

  const updateFilter = (f: Filter) => {
    setFilter(f);
    debouncedSetFilter(f);
  };

  useEffect(() => {
    const f = { ...(defaultFilters[currentType] || EMPTY_FILTER) };
    setFilter(f);
    debouncedSetFilter(f);
  }, [currentType, defaultFilters, setFilter, debouncedSetFilter]);

  const count = useSelector(searchCountSelector(currentType, delayedFilter));

  const { items, fetching } = useSelector(searchItemSelector)(
    currentType,
    delayedFilter
  );

  useEffect(() => {
    setCurrentType(type);
  }, [type]);

  useEffect(() => {
    dispatch(doSearch(currentType, delayedFilter));
  }, [dispatch, currentType, delayedFilter]);

  useEffect(() => {
    setSelectAll(false);
    setSelectedRowKey([]);
    setCanSelectAll(!filter.search);
  }, [dispatch, filter, currentType]);

  const prevSelectAll = usePrevious(isSelectAll);
  useEffect(() => {
    setPage(1);
  }, [match.path]);

  useEffect(() => {
    if (isSelectAll !== prevSelectAll) {
      if (isSelectAll) {
        setSelectedRowKey(items.map(el => el.id));
      } else {
        setSelectedRowKey([]);
      }
    }
  }, [isSelectAll, prevSelectAll, items]);

  const onPaginationChange = (newPage: number, newPageSize?: number) => {
    setPage(newPage);
    if (newPageSize) {
      setPageSize(newPageSize);
    }
  };

  const selectionSize = isSelectAll ? count || 0 : selectedRowKeys.length;
  const createSelection = async () => {
    let query;
    if (!isSelectAll) {
      query = selectedRowKeys.map(el => ({ id: el }));
    } else {
      query = filter;
      if (selectionSize > 10000) {
        query = {
          ...query,
          limit: 1000,
        };
      }
    }
    const selection: PendingResourceSelection = {
      type: currentType,
      endpoint: isSelectAll ? 'list' : 'retrieve',
      query,
    };
    if (onNextClicked(selectionSize, selection)) {
      if (selectionSize === 0) {
        message.error('You have to select data to continue');
      }
      const selectionId = await dispatch(create(selection));
      history.push(`${match.url}/${selectionId}`);
    }
  };
  const types = uniq([currentType, ...availableTypes]);
  types.sort();

  return (
    <>
      <Row type="flex" gutter={[0, 20]}>
        <Col>
          <h1>Select data</h1>
          {availableTypes.length > 0 && (
            <Select
              defaultValue={currentType}
              onChange={(rt: ResourceType) => setCurrentType(rt)}
            >
              {types.map(t => (
                <Select.Option key={t} value={t as string}>
                  {t}
                </Select.Option>
              ))}
            </Select>
          )}
        </Col>
      </Row>
      <Row gutter={[16, 20]} type="flex" justify="space-around">
        {getSearchBar(currentType, filter, updateFilter)}
      </Row>
      <Row gutter={[0, 20]} type="flex">
        <Col span={24}>
          <p id="count-text">
            {count} results. {selectionSize} selected.
          </p>
        </Col>
      </Row>
      <Table
        style={{ marginBottom: '90px' }}
        rowSelection={{
          columnTitle: (
            <Tooltip
              title={
                canSelectAll
                  ? ''
                  : 'When you have a search query, you cannot select all'
              }
            >
              <Button
                style={{ width: '120px' }}
                type={isSelectAll ? 'primary' : 'secondary'}
                disabled={!canSelectAll}
                icon="Check"
                onClick={() => setSelectAll(!isSelectAll)}
              >
                Select all
              </Button>
            </Tooltip>
          ),
          getCheckboxProps: () => {
            if (isSelectAll) {
              return { disabled: true };
            }
            return {};
          },
          onSelect: (asset, selected) => {
            if (isSelectAll) {
              message.info(
                'Currently only manual selection or select all is supported.'
              );
            } else if (selected) {
              setSelectedRowKey([...selectedRowKeys, asset.id]);
            } else {
              setSelectedRowKey(selectedRowKeys.filter(el => el !== asset.id));
            }
          },
          selectedRowKeys,
        }}
        columns={getColumns(currentType)}
        rowKey="id"
        pagination={{
          position: 'bottom',
          showQuickJumper: true,
          showSizeChanger: true,
          pageSize,
          current: page,
          onChange: onPaginationChange,
          onShowSizeChange: onPaginationChange,
        }}
        loading={fetching}
        // since sometimes the JS object from SDK has a .children object.
        dataSource={items.map(el => ({ ...el }))}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={noDataText || `No ${currentType} found`}
            />
          ),
        }}
      />
      <StickyBottomRow>
        <Button size="large" type="secondary" onClick={() => history.goBack()}>
          Back
        </Button>
        <div>
          <Typography.Text strong>{selectionSize} selected</Typography.Text>
          <Button
            size="large"
            type="primary"
            disabled={selectionSize === 0}
            onClick={createSelection}
            style={{ marginLeft: '20px' }}
          >
            Next
          </Button>
        </div>
      </StickyBottomRow>
      <ResourceSidebar />
    </>
  );
}
