import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Input, Icon, Empty, Spin, Affix, Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  search as filesSearch,
  searchSelector as searchFileSelector,
} from 'modules/files';
import {
  search as assetsSearch,
  searchSelector as searchAssetSelector,
  retrieve,
  itemSelector,
} from 'modules/assets';
import { FilesMetadata } from '@cognite/sdk';
import { Popover } from 'components/Common/';
import {
  AssetHoverPreview,
  FileHoverPreview,
  RenderResourceActionsFunction,
} from 'containers/HoverPreview';
import { Button } from '@cognite/cogs.js';

const Overlay = styled.div<{ visible: boolean }>`
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  z-index: 2;
  pointer-events: all;
`;

const SearchWrapper = styled.div`
  z-index: 1001;
  width: auto;
  flex: unset;
  position: relative;
`;

const SearchBar = styled.div<{ extended: boolean }>`
  pointer-events: all;
  width: 340px;
  box-shadow: 0px 0px 6px #cdcdcd;
  border-radius: 24px;
  font-size: 26px;
  display: flex;
  padding: 4px 8px;
  background: #fff;
  transition: 0.3s all;

  .ant-input-affix-wrapper,
  input,
  input:focus,
  input:active {
    border: none;
    outline: none;
    box-shadow: none;
  }
  .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
    border: none;
    outline: none;
    background: #fff;
  }
`;

const ResultList = styled.div<{ visible: boolean }>`
  position: absolute;
  pointer-events: all;
  height: ${props => (props.visible ? '600px' : '0px')};
  z-index: 1001;
  margin-top: 16px;
  width: 340px;
  transition: 0.3s all;
  overflow: hidden;
  box-shadow: 0px 0px 6px #cdcdcd;
  background: white;
  border-radius: 12px;
  padding: 16px;
  padding-top: ${props => (props.visible ? '16px' : '0px')};
  padding-bottom: ${props => (props.visible ? '16px' : '0px')};
  overflow: auto;
  display: flex;
  flex-direction: row;
  margin-bottom: ${props => (props.visible ? '24px' : '0px')};

  small {
    background: #fff;
    padding: 6px 8px;
    display: block;
    font-weight: 700;
  }

  & > * {
    width: 0;
    flex: 1;
  }
`;

export const ListItem = styled.div<{ selected?: boolean }>`
  padding: 6px 8px;
  transition: 0.2s all;
  cursor: pointer;
  border-bottom: 1px solid #cdcdcd;
  position: relative;
  background: ${props => (props.selected ? '#fafafa' : '#fff')};

  &&:hover {
    background: #efefef;
  }

  &&:nth-last-child(1) {
    border-bottom: none;
  }

  p {
    margin-bottom: 0px;
    word-wrap: nowrap;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 340px;
    overflow: hidden;
  }
  p.name {
    margin-bottom: 8px;
    word-wrap: wrap;
    white-space: normal;
    color: #000;
    font-size: 16px;
  }
`;

const createFileScopeWithQuery = (query: string) => {
  return {
    search: { name: query },
    filter: {
      mimeType: 'application/pdf',
    },
  };
};

const createAssetQueryFilter = (query: string) => {
  return {
    ...(query.length > 0 && { search: { name: query } }),
  };
};

type Props = {
  onFileSelected: (file: FilesMetadata) => void;
  onAssetSelected: (assetId: number) => void;
  currentFileId?: number;
  renderResourceActions?: RenderResourceActionsFunction;
};

export default function PnIDSearch({
  onFileSelected,
  onAssetSelected,
  currentFileId,
  renderResourceActions = () => [],
}: Props) {
  const resultRef = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();
  const [query, setQuery] = useState('');
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [activeKey, setActiveKey] = useState('files');
  const assetsMap = useSelector(itemSelector);

  const { items: allFiles, fetching: filesFetching } = useSelector(
    searchFileSelector
  )(createFileScopeWithQuery(query));

  const files = allFiles.slice(0, 10);

  const { items: allAssets, fetching: assetsFetching } = useSelector(
    searchAssetSelector
  )(createAssetQueryFilter(query));

  const assets = allAssets.slice(0, 10);

  // On query change
  useEffect(() => {
    dispatch(filesSearch(createFileScopeWithQuery(query)));
    dispatch(assetsSearch(createAssetQueryFilter(query)));
  }, [query, dispatch]);

  // Load missing root asset info
  const assetIds = new Set<number>();
  assets.forEach(asset => {
    if (asset.rootId && !assetsMap(asset.rootId)) {
      assetIds.add(asset.rootId);
    }
  });

  useEffect(() => {
    if (!assetsFetching && assetIds && assetIds.size > 0) {
      dispatch(retrieve([...assetIds].map(id => ({ id }))));
    }
  }, [assetIds, assetsFetching, dispatch]);

  const onFileClicked = (file: FilesMetadata) => {
    setShowSearchResults(false);
    onFileSelected(file);
  };

  const onAssetClicked = (id: number) => {
    setShowSearchResults(false);
    onAssetSelected(id);
  };

  const renderFiles = (fileList: FilesMetadata[], isFetching: boolean) => {
    return (
      <div>
        {fileList.map(el => {
          const actions = renderResourceActions({ fileId: el.id });
          return (
            <Popover
              key={el.id}
              content={
                <FileHoverPreview
                  file={el}
                  disableSidebarToggle
                  actions={[
                    <Button onClick={() => onFileClicked(el)} icon="Select">
                      Open File
                    </Button>,
                    ...actions,
                  ]}
                />
              }
            >
              <ListItem
                key={el.id}
                onClick={() => onFileClicked(el)}
                selected={el.id === currentFileId}
              >
                <p className="name">{el.name}</p>
                <p>External ID: {el.externalId}</p>
              </ListItem>
            </Popover>
          );
        })}
        {fileList.length === 0 && !isFetching && (
          <Empty description="No File Found" />
        )}
        {fileList.length === 0 && isFetching && <Spin />}
      </div>
    );
  };

  return (
    <>
      <Overlay
        visible={showSearchResults}
        onClick={() => setShowSearchResults(false)}
      />
      <SearchWrapper>
        <SearchBar
          extended={!showSearchResults}
          onClick={() => setShowSearchResults(true)}
        >
          <Input
            prefix={
              <Icon
                type={showSearchResults ? 'arrow-left' : 'search'}
                style={{ marginRight: '8px', cursor: 'pointer', zIndex: 1002 }}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  setShowSearchResults(!showSearchResults);
                }}
              />
            }
            placeholder="Search for a file or asset..."
            onChange={ev => setQuery(ev.target.value)}
            value={query}
          />
        </SearchBar>
        <ResultList visible={showSearchResults} ref={resultRef}>
          <div>
            <Affix
              style={{ display: showSearchResults ? 'block' : 'none' }}
              target={() =>
                resultRef && resultRef.current ? resultRef.current : null
              }
            >
              <Tabs
                activeKey={activeKey}
                onChange={key => setActiveKey(key)}
                style={{ backgroundColor: '#fff' }}
                tabBarGutter={0}
              >
                <Tabs.TabPane
                  tab={
                    <span>
                      Files ({files.length}
                      {allFiles.length > 10 && '+'})
                    </span>
                  }
                  key="files"
                />
                <Tabs.TabPane
                  tab={
                    <span>
                      Assets ({assets.length}
                      {allAssets.length > 10 && '+'})
                    </span>
                  }
                  key="assets"
                />
              </Tabs>
            </Affix>
            {activeKey === 'assets' ? (
              <>
                {assets.map(el => {
                  const actions = renderResourceActions({ assetId: el.id });
                  return (
                    <Popover
                      key={el.id}
                      content={
                        <AssetHoverPreview
                          asset={el}
                          renderResourceActions={renderResourceActions}
                          actions={actions}
                        />
                      }
                    >
                      <ListItem
                        style={{ cursor: 'default' }}
                        onClick={() => onAssetClicked(el.id)}
                      >
                        <p className="name">{el.name}</p>
                        <p>{el.description}</p>
                        <p>
                          Root Asset:{' '}
                          {assetsMap(el.rootId)
                            ? assetsMap(el.rootId)!.name
                            : 'Loading...'}
                        </p>
                      </ListItem>
                    </Popover>
                  );
                })}
                {assets.length === 0 && !assetsFetching && (
                  <Empty description="No Asset Found" />
                )}
                {assets.length === 0 && assetsFetching && <Spin />}
              </>
            ) : (
              renderFiles(files, filesFetching)
            )}
          </div>
        </ResultList>
      </SearchWrapper>
    </>
  );
}
