import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Layout } from 'antd';
import Create from 'containers/Create';
import Loader from 'components/Loader';
import { setTenant, setCdfEnv } from 'modules/app';
import { authenticate, status as loginStatus } from 'modules/login';
import {
  EntityMatchingRoutes,
  PnIDParsingRoutes,
  DocumentContextualizeRoutes,
} from 'containers/Contextualization';
import { RootState } from 'reducers/index';
import queryString from 'query-string';
import Functions from 'containers/Functions';
import SwitchWithBreadcrumbs from 'components/SwitchWithBreadcrumbs';
import { trackUsage } from 'utils/Metrics';
import * as mixpanelConfig from 'mixpanel-browser';
import { useLocation, useRouteMatch, useHistory } from 'react-router-dom';
import { PnIDViewerRoutes } from 'containers/Exploration/PnIDViewer';
import Navigation from 'components/Navigation';

function PageNotFound() {
  return <h1>Page not found</h1>;
}

export default function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const {
    params: { tenant: pathTenant },
  } = useRouteMatch<{ tenant: string }>();
  const user = useSelector((state: RootState) => state.login.status?.user);
  const { tenant, cdfEnv } = useSelector((state: RootState) => state.app);
  const isAuthenticated = useSelector(
    (state: RootState) => state.login.isAuthenticated && state.app.loaded
  );

  useEffect(() => {
    verifyAuth();
  });

  useEffect(() => {
    if (user) {
      const company = user.split('@').pop();
      // @ts-ignore
      mixpanelConfig.datastudio.add_group('company', company);
      // @ts-ignore
      mixpanelConfig.datastudio.identify(user);
    }
  }, [user]);

  useEffect(() => {
    trackUsage('App.navigation');
  }, [location]);

  async function verifyAuth() {
    const fromUrlCdfEnv = queryString.parse(location.search).env as string;
    if (!cdfEnv && fromUrlCdfEnv) {
      dispatch(setCdfEnv(fromUrlCdfEnv));
    }
    if (cdfEnv && !fromUrlCdfEnv) {
      if (tenant) {
        // if env is not visible via URL add it in
        history.replace({
          pathname: location.pathname,
          search: `?env=${cdfEnv}`,
        });
      } else {
        dispatch(setCdfEnv(undefined));
      }
    }

    dispatch(authenticate(tenant || pathTenant));
    dispatch(loginStatus());

    if (!tenant && pathTenant) {
      dispatch(setTenant(pathTenant));
    }
  }

  if (!isAuthenticated) {
    return <Loader />;
  }
  const routes = [
    { path: '/:tenant/', exact: true, component: Create },
    { path: '/:tenant/entity_matching', component: EntityMatchingRoutes },
    { path: '/:tenant/pnid_parsing', component: PnIDParsingRoutes },
    { path: '/:tenant/explore/pnid', component: PnIDViewerRoutes },
    {
      path: '/:tenant/document_contextualize',
      component: DocumentContextualizeRoutes,
    },
    { path: '/:tenant/functions', component: Functions },
    { path: '/:tenant/*', component: PageNotFound },
  ];
  return (
    <Layout>
      <Navigation
        appName="Cognite Data Fusion"
        projectName={tenant}
        user={user}
      />
      <Layout.Content style={{ marginTop: 64 }}>
        <SwitchWithBreadcrumbs routes={routes} />
      </Layout.Content>
    </Layout>
  );
}
