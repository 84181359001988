import React, { useState, useEffect } from 'react';
import { Progress, Col, Row, Collapse } from 'antd';
import { useSelector } from 'react-redux';
import { Asset, FilesMetadata } from '@cognite/sdk';
import { selectParsingJobForFileId } from 'modules/fileContextualization/parsingJobs';
import { dataKitItemsSelector } from 'modules/selection';
import { Result } from 'modules/sdk-builder/types';

interface LoadResourcesProps {
  assetDataKitId: string;
  fileDataKitId: string;
}
export default function LoadResources(props: LoadResourcesProps) {
  const { assetDataKitId, fileDataKitId } = props;
  const [collapseExpanded, setCollapseExpanded] = useState<boolean>(true);

  const { progress: assetProgress, items: assets } = useSelector(
    dataKitItemsSelector
  )(assetDataKitId, true, true) as Result<Asset>;

  const { progress: fileProgress, items } = useSelector(dataKitItemsSelector)(
    fileDataKitId,
    true,
    true
  ) as Result<FilesMetadata>;

  const files = items.filter(el => !!el);

  const getParsingJob = useSelector(selectParsingJobForFileId);

  const filesCompleted = files.filter(el => {
    const jobStatus = getParsingJob(el.id);
    if (jobStatus && jobStatus.jobDone) {
      return true;
    }
    return false;
  });

  const jobDone = filesCompleted.length === files.length;

  useEffect(() => {
    setCollapseExpanded(jobDone);
  }, [jobDone]);

  const assetPercent: number = Math.floor(assetProgress * 100);
  const filesPercent: number = Math.floor(fileProgress * 100);
  const parsingJobPercent: number = Math.floor(
    (filesCompleted.length / files.length) * 100
  );
  return (
    <Collapse
      activeKey={collapseExpanded ? undefined : '1'}
      onChange={() => setCollapseExpanded(!collapseExpanded)}
    >
      <Collapse.Panel
        header={jobDone ? 'Resources loaded' : 'Loading resources'}
        key="1"
      >
        <Row>
          <Col span={4}>
            <p>Files</p>
          </Col>
          <Col span={20}>
            <Progress
              percent={filesPercent}
              status={fileProgress === 1 ? 'success' : 'active'}
            />
          </Col>
        </Row>
        <Row>
          <Col span={4}>
            <p>Assets</p>
          </Col>
          <Col span={20}>
            <Progress
              percent={assetPercent}
              status={assetProgress === 1 ? 'success' : 'active'}
            />
          </Col>
        </Row>
        <Row>
          <Col span={4}>
            <p>Parsing Jobs</p>
          </Col>
          <Col span={20}>
            <Progress
              percent={parsingJobPercent}
              status={parsingJobPercent === files.length ? 'success' : 'active'}
            />
          </Col>
        </Row>
        <p>
          Loaded {files.length} files and {assets.length} assets
        </p>
      </Collapse.Panel>
    </Collapse>
  );
}
