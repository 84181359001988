import React, { useEffect } from 'react';
import { Card, Row, Col, Alert } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'reducers';
import Link from 'components/Link';
import EntityMatchingImage from 'assets/entity-matching.png';
import CogniteFunctionsImage from 'assets/cognite-functions.png';
import DocumentContextualizationImage from 'assets/document-contextualization.png';
import DsHubImage from 'assets/dshub.png';
import PnidContextualizationImage from 'assets/pnid-contextualization.png';
import ScaleUseCasesImage from 'assets/scale-use-cases.png';
import DetectSimilarImage from 'assets/detect-similar.png';
import PnIDExplorerImage from 'assets/pnid-explorer.png';

import { isCogniteUser, status as loginStatus } from 'modules/login';
import { Icon, Colors } from '@cognite/cogs.js';
import { AlphaBadge } from 'components/AlphaBadge';

type ContextualizationCardProps = {
  title: string;
  description: string;
  image?: string;
  enabled?: boolean;
  url?: string;
  externalUrl?: string;
};

export default function Create() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loginStatus());
  }, [dispatch]);

  const tenant = useSelector((state: RootState) => state.app.tenant);
  const internalUser = useSelector(isCogniteUser);

  const isStaging = window.location.hostname.indexOf('staging') === 0;
  const isLocal = window.location.hostname.indexOf('localhost') === 0;

  const showPrototypes = (isStaging || isLocal) && internalUser;

  const apps: ContextualizationCardProps[] = [
    {
      title: 'Match Resource Types',
      enabled: true,
      url: `/${tenant}/entity_matching`,
      image: EntityMatchingImage,
      description:
        'Match resources (assets, time series, events, 3D nodes) to their appropriate counterparts to create belongs_to relations.',
    },
    {
      title: 'Contextualize P&IDs',
      enabled: true,
      url: `/${tenant}/pnid_parsing`,
      image: PnidContextualizationImage,
      description: 'Extract information from process and instrument diagrams.',
    },
    {
      title: 'Contextualize Documents',
      enabled: true,
      url: `/${tenant}/document_contextualize`,
      image: DocumentContextualizationImage,
      description: 'Extract information from documents.',
    },
    {
      title: 'Scale Use Cases',
      enabled: false,
      image: ScaleUseCasesImage,
      description:
        'Leverage data types and scale use case solutions from one to many.',
    },
  ];

  const internalApps: ContextualizationCardProps[] = [
    {
      title: 'Cognite Function',
      url: `/${tenant}/functions`,
      image: CogniteFunctionsImage,
      description: 'Select or upload a function to create a data pipeline.',
    },
    {
      title: 'Jupyter Notebook',
      enabled: internalUser,
      externalUrl: 'https://dshub.dev.cognite.ai/',
      image: DsHubImage,
      description: 'Develop code in a Jupyter Notebook.',
    },
  ];

  const explorationApps: ContextualizationCardProps[] = [
    {
      title: 'P&ID Viewer',
      url: `/${tenant}/explore/pnid`,
      image: PnidContextualizationImage,
      enabled: true,
      description: 'Explore CDF via contextualized P&IDs',
    },
  ];

  const prototypes = [
    {
      title: 'P&ID Explorer',
      enabled: true,
      externalUrl: 'https://pr-280.data-studio.preview.cogniteapp.com/',
      image: PnIDExplorerImage,
      description:
        'Navigate contextualized P&ID directly via search and previewing assets, files and linkages.',
    },
    {
      title: 'Object Detection',
      enabled: true,
      externalUrl: 'https://pr-215.data-studio.preview.cogniteapp.com/',
      image: DetectSimilarImage,
      description:
        'Enable the ability to find similar objects based on a box drawn on the P&ID.',
    },
  ];

  return (
    <>
      <h1>Contextualization</h1>
      <div>
        <Row type="flex" gutter={[16, 10]}>
          {apps.map(app => {
            const card = (
              <Card
                title={app.title}
                hoverable={app.enabled}
                style={{
                  height: '100%',
                  opacity: app.enabled ? 1.0 : 0.5,
                }}
              >
                <Card.Meta description={app.description} />
                <div style={{ flex: 1 }} />
                <img
                  style={{ width: '100%', height: 'auto' }}
                  alt={app.title}
                  src={app.image}
                />
              </Card>
            );
            return (
              <Col xs={24} sm={12} md={8} lg={6} xl={4} key={app.title}>
                {app.enabled ? <Link to={app.url}>{card}</Link> : card}
              </Col>
            );
          })}
        </Row>
      </div>
      <h1>Exploration</h1>
      <div>
        <Row type="flex" gutter={[16, 10]}>
          {explorationApps.map(app => {
            const card = (
              <Card
                title={app.title}
                hoverable={app.enabled}
                style={{
                  height: '100%',
                  opacity: app.enabled ? 1.0 : 0.5,
                }}
              >
                <Card.Meta description={app.description} />
                <div style={{ flex: 1 }} />
                <img
                  style={{ width: '100%', height: 'auto' }}
                  alt={app.title}
                  src={app.image}
                />
              </Card>
            );
            return (
              <Col xs={24} sm={12} md={8} lg={6} xl={4} key={app.title}>
                {app.enabled ? <Link to={app.url}>{card}</Link> : card}
              </Col>
            );
          })}
        </Row>
      </div>
      {internalUser && (
        <div id="internal-tools">
          <h1>Create Your Own</h1>

          <Row type="flex" gutter={[16, 10]}>
            {internalApps.map(app => (
              <Col xs={24} sm={12} md={8} lg={6} xl={4} key={app.title}>
                <Link to={app.url} href={app.externalUrl}>
                  <Card
                    title={app.title}
                    hoverable={app.enabled}
                    style={{ height: '100%' }}
                  >
                    <Card.Meta description={app.description} />
                    <div style={{ flex: 1 }} />
                    <img
                      style={{ width: '100%', height: 'auto' }}
                      alt=""
                      src={app.image}
                    />
                  </Card>
                </Link>
              </Col>
            ))}
          </Row>
        </div>
      )}
      {showPrototypes && (
        <div id="prototypes">
          <h1>Prototypes</h1>
          <Alert
            showIcon
            message={
              <span>
                These prototypes are alpha experiences. While you may experience
                some bugs or instability, you have an opportunity to try the
                latest and greatest features and functionalities. Please help us
                build them into the best possible product experiences! Please
                let us know if you{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://cog.link/cdf_bug"
                >
                  find a bug
                </a>{' '}
                or have{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://cognitedata.slack.com/archives/CNVSY5Q6S"
                >
                  questions and feedback
                </a>
                .
              </span>
            }
            type="error"
            icon={<Icon type="Help" />}
            style={{
              marginTop: '24px',
              marginBottom: '24px',
              maxWidth: '640px',
            }}
          />
          <div>
            <Row type="flex" gutter={[16, 10]}>
              {prototypes.map(app => (
                <Col xs={24} sm={12} md={8} lg={6} xl={4} key={app.title}>
                  <Link
                    href={`${app.externalUrl}${tenant}${window.location.search}`}
                  >
                    <Card
                      title={
                        <>
                          <span>{app.title}</span>
                          <Icon
                            type="Link"
                            style={{
                              marginLeft: '4px',
                              color: Colors.primary.hex(),
                            }}
                          />
                        </>
                      }
                      extra={<AlphaBadge />}
                      hoverable={app.enabled}
                      style={{ height: '100%' }}
                    >
                      <Card.Meta description={app.description} />
                      <div style={{ flex: 1 }} />
                      <img
                        style={{ width: '100%', height: 'auto' }}
                        alt=""
                        src={app.image}
                      />
                    </Card>
                  </Link>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      )}
    </>
  );
}
