import React, { useState } from 'react';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import { Button, Colors } from '@cognite/cogs.js';
import { CountLabel, Popover } from 'components/Common';
import { itemSelector as fileSelector } from 'modules/files';
import { itemSelector as timeseriesSelector } from 'modules/timeseries';
import { itemSelector as assetsSelector } from 'modules/assets';
import {
  ShoppingCart,
  ShoppingCartPreview,
} from 'containers/Exploration/PnIDViewer/ShoppingCartPreview';
import ShoppingCartIcon from 'assets/shopping-cart.svg';
import { useSelector } from 'react-redux';
import { ResourceSidebar } from 'containers/ResourceSidebar';
import { RenderResourceActionsFunction } from 'containers/HoverPreview';
import PnIDViewer from './PnIDViewer';

const Wrapper = styled.div`
  flex: 1;
  height: calc(100vh - 160px);
  position: relative;

  .search-content {
    background: #fff;
  }
`;

export const FullScreenPnIDViewer = () => {
  const history = useHistory();
  const { tenant } = useParams<{ tenant: string }>();
  const [cart, setCart] = useState<ShoppingCart>({
    assets: {},
    timeseries: {},
    files: {},
  });
  const getFile = useSelector(fileSelector);
  const getTimeseries = useSelector(timeseriesSelector);
  const getAssets = useSelector(assetsSelector);

  const cartCount = Object.values(cart).reduce(
    (prev, item) => prev + Object.values(item).length,
    0
  );

  const renderResourceActions: RenderResourceActionsFunction = ({
    fileId,
    assetId,
    timeseriesId,
  }) => {
    const checkIsInCart = () => {
      if (fileId && cart.files[fileId]) {
        return true;
      }
      if (timeseriesId && cart.timeseries[timeseriesId]) {
        return true;
      }
      if (assetId && cart.assets[assetId]) {
        return true;
      }
      return false;
    };

    const isInCart = checkIsInCart();

    return [
      <Button
        key="add-to-cart"
        type={isInCart ? 'secondary' : 'primary'}
        onClick={() => {
          if (isInCart) {
            const newCart = { ...cart };
            if (fileId) {
              delete newCart.files[fileId];
            } else if (timeseriesId) {
              delete newCart.timeseries[timeseriesId];
            } else if (assetId) {
              delete newCart.assets[assetId];
            }
            setCart(newCart);
          } else if (fileId && getFile(fileId)) {
            setCart({
              ...cart,
              files: { ...cart.files, [fileId]: getFile(fileId)! },
            });
          } else if (timeseriesId && getTimeseries(timeseriesId)) {
            setCart({
              ...cart,
              timeseries: {
                ...cart.timeseries,
                [timeseriesId]: getTimeseries(timeseriesId)!,
              },
            });
          } else if (assetId && getAssets(assetId)) {
            setCart({
              ...cart,
              assets: { ...cart.assets, [assetId]: getAssets(assetId)! },
            });
          }
        }}
      >
        {isInCart ? 'Remove from Cart' : 'Add to Cart'}
      </Button>,
    ];
  };

  return (
    <Wrapper>
      <Popover
        content={
          <ShoppingCartPreview
            cart={cart}
            setCart={setCart}
            renderResourceActions={renderResourceActions}
          />
        }
        placement="bottomRight"
      >
        <Button
          style={{ position: 'absolute', right: 24, top: -40 }}
          type="primary"
        >
          <img
            src={ShoppingCartIcon}
            alt=""
            style={{ height: 16, width: 16, marginRight: 8 }}
          />
          <span style={{ marginRight: 8 }}>Cart</span>
          <CountLabel
            backgroundColor={Colors['midblue-5'].hex()}
            color={Colors.white.hex()}
            value={cartCount}
          />
        </Button>
      </Popover>
      <PnIDViewer
        renderResourceActions={renderResourceActions}
        onFileClicked={newFile =>
          history.push(`/${tenant}/explore/pnid/${newFile.id}`)
        }
      />
      <ResourceSidebar extraButtons={renderResourceActions} />
    </Wrapper>
  );
};
