import queryString from 'query-string';
import { Section } from './types';

const colors = {
  pink: {
    primary: '#FC2574',
    secondary: '#FFEAF2',
  },
  blue: {
    primary: '#4A67FB',
    secondary: '#DBE1FE',
  },
  orange: {
    primary: '#FF6918',
    secondary: '#FFE1D1',
  },
  yellow: {
    primary: '#FFBB00',
    secondary: '#FFF1CC',
  },
  green: {
    primary: '#1AA3C1',
    secondary: '#D3F7FB',
  },
  lightBlue: {
    primary: '#21d7ec',
    secondary: '#d3f7fb',
  },
  purple: {
    primary: '#c945db',
    secondary: ' #f4daf8',
  },
  black: {
    primary: '#404040',
    secondary: '#404040',
    icon: '#FFFFFF',
  },
  greenWithBackground: {
    primary: '#1AA3C1',
    secondary: '#1AA3C1',
    background: '#EAFCFE',
    icon: '#FFFFFF',
  },
};

const getProjectNameFromUrl = () => window.location.pathname.split('/')[1];

const getCdfEnvFromUrl = () => queryString.parse(window.location.search).env;

const isStaging = () => window.location.hostname.indexOf('staging') === 0;
const isLocal = () => window.location.hostname.indexOf('localhost') === 0;
const isProduction = () => !(isStaging() || isLocal());

const createConsoleLink = (path: string): string => {
  let appEnv = '';
  if (!isProduction()) {
    appEnv = 'staging.';
  }

  const projectName = getProjectNameFromUrl() || '';

  let cdfEnv = getCdfEnvFromUrl() || '';
  if (cdfEnv) {
    cdfEnv = `?env=${cdfEnv}`;
  }

  return `https://${appEnv}console.cognitedata.com/${projectName}${path}${cdfEnv}`;
};

const createDataStudioLink = (path: string): string => {
  const projectName = getProjectNameFromUrl() || '';
  return `/${projectName}${path}`;
};

const dataIngestion: Section = {
  title: 'Data ingestion',
  externalLinkTo: createConsoleLink('/ingest'),
  sameWindow: true,
  colors: colors.pink,
  items: [
    {
      icon: 'cluster',
      title: 'Extractors',
      subtitle: 'Download extractors to ingest data from your source systems',
      externalLinkTo: createConsoleLink('/extractors'),
      sameWindow: true,
    },
    {
      icon: 'database',
      title: 'RAW explorer',
      subtitle: 'Stage data in RAW before transforming it into data sets',
      externalLinkTo: createConsoleLink('/raw'),
      sameWindow: true,
    },
    {
      icon: 'rocket',
      title: 'Transformations',
      subtitle: 'Transform data into data sets',
      externalLinkTo: createConsoleLink('/transformations'),
      sameWindow: true,
    },
  ],
};

const dataCatalog: Section = {
  title: 'Data catalog',
  externalLinkTo: createConsoleLink('/data-catalog'),
  sameWindow: true,
  colors: colors.blue,
  items: [
    {
      icon: 'reconciliation',
      title: 'Data sets',
      subtitle: 'Browse and manage data sets',
      externalLinkTo: createConsoleLink('/data-sets'),
      sameWindow: true,
    },
    {
      icon: 'code-sandbox',
      title: '3D models',
      subtitle: 'Upload and browse 3D models',
      externalLinkTo: createConsoleLink('/3d-models'),
      sameWindow: true,
    },
  ],
};

const admin: Section = {
  title: 'Admin',
  externalLinkTo: createConsoleLink('/admin'),
  sameWindow: true,
  colors: colors.yellow,
  items: [
    {
      icon: 'team',
      title: 'Access management',
      subtitle: 'Control data access for users, apps, and services',
      externalLinkTo: createConsoleLink('/access-management'),
      sameWindow: true,
    },
  ],
};

const dataMonitoring: Section = {
  title: 'Data monitoring',
  externalLinkTo: createConsoleLink('/monitoring'),
  sameWindow: true,
  colors: colors.orange,
  items: [
    {
      icon: 'line-chart',
      title: 'Time series quality monitor',
      subtitle:
        'Track the data quality to ensure that your data stays reliable',
      externalLinkTo: createConsoleLink('/quality-monitoring'),
      sameWindow: true,
    },
  ],
};

const apps: Section = {
  title: 'Apps',
  externalLinkTo: createConsoleLink('/apps'),
  sameWindow: true,
  colors: colors.purple,
  items: [
    {
      icon: 'shake',
      title: 'InField',
      subtitle: 'Configure the InField application',
      externalLinkTo: createConsoleLink('/infield'),
      sameWindow: true,
    },
  ],
};

const contextualization: Section = {
  title: 'Contextualization',
  colors: colors.lightBlue,
  items: [
    {
      icon: 'database',
      title: 'Match resources',
      subtitle: 'Match resources to their corresponding entities',
      linkTo: createDataStudioLink('/entity_matching'),
    },
    {
      icon: 'database',
      title: 'Contextualize P&IDs',
      subtitle: 'Extract information from process and instrument diagrams',
      linkTo: createDataStudioLink('/pnid_parsing'),
    },
    {
      icon: 'database',
      title: 'Contextualize documents',
      subtitle: 'Extract information from documents',
      disabled: true,
    },
    {
      icon: 'database',
      title: 'Scale use cases',
      subtitle:
        'Leverage data types and scale use case solutions from one to many',
      disabled: true,
    },
  ],
};

const createYourOwn: Section = {
  title: 'Create your own',
  colors: colors.black,
  items: [
    {
      icon: 'database',
      title: 'Cognite functions',
      subtitle: 'Upload, run, schedule and view logs for functions',
      linkTo: createDataStudioLink('/functions'),
    },
    {
      icon: 'database',
      title: 'Jupyter notebook',
      subtitle: 'Develop and run code in Jupyter Notebooks',
      externalLinkTo: 'https://dshub.dev.cognite.ai/',
    },
  ],
};

const documentation: Section = {
  title: 'Documentation & Support',
  colors: colors.greenWithBackground,
  items: [
    {
      icon: 'file-search',
      title: 'Documentation',
      subtitle:
        'Learn how to manage and use your data and insights efficiently',
      externalLinkTo: 'https://docs.cognite.com/cdf/',
    },
  ],
};

const defaultSections: Section[] = [
  dataIngestion,
  dataCatalog,
  admin,
  dataMonitoring,
  apps,
  contextualization,
  createYourOwn,
  documentation,
];

export default defaultSections;
