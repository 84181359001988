import React from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router';
import Breadcrumbs, { BreadcrumbRouteMapFn } from 'components/Breadcrumbs/';

type RouteDef = {
  exact?: boolean;
  strict?: boolean;
  path: string;
  component: any;
};

export default function SwitchWithBreadcrumbs(props: { routes: RouteDef[] }) {
  const { pathname } = useLocation();
  const routesMap: BreadcrumbRouteMapFn[] = props.routes.reduce(
    (accl, route) => {
      if (route.component.breadcrumbs) {
        return accl.concat([
          route.component.breadcrumbs(route.path) as BreadcrumbRouteMapFn,
        ]);
      }
      return accl;
    },
    [] as BreadcrumbRouteMapFn[]
  );
  return (
    <>
      <Breadcrumbs routesMap={routesMap} />
      <Switch>
        <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
        {props.routes.map(route => (
          <Route
            key={route.path}
            exact={!!route.exact}
            stric={!!route.strict}
            path={route.path}
            component={route.component}
          />
        ))}
      </Switch>
    </>
  );
}
