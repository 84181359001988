import React, { useEffect, useState } from 'react';
import { message, Modal } from 'antd';
import { IAnnotation, IRectShapeData } from '@cognite/react-picture-annotation';
import styled from 'styled-components';
import {
  CogniteAnnotation,
  PendingCogniteAnnotation,
  CURRENT_VERSION,
} from '@cognite/annotations';
import {
  retrieve as retrieveAssets,
  retrieveExternal as retrieveExternalAssets,
  itemSelector as assetSelector,
} from 'modules/assets';
import { useSelector, useDispatch } from 'react-redux';
import {
  create as createAnnotations,
  remove as removeAnnotations,
  selectAnnotations,
} from 'modules/annotations';
import { canEditEvents } from 'utils/PermissionUtils';
import { trackUsage } from 'utils/Metrics';
import queryString from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';
import { Button, Menu } from '@cognite/cogs.js';
import {
  itemSelector as fileSelector,
  retrieve as retrieveFiles,
} from 'modules/files';
import { onResourceSelected } from 'modules/app';
import { findSimilarObjects } from 'modules/fileContextualization/similarObjectJobs';
import { v4 as uuid } from 'uuid';
import { RootState } from 'reducers';
import { SmallTitle, PDFViewer } from 'components/Common';
import { FilesMetadata, Asset } from '@cognite/sdk';
import { RenderResourceActionsFunction } from 'containers/HoverPreview';
import { AnnotatedPnIDItemEditor } from './AnnotatedPnIDItemEditor';
import { IAnnotationWithPage } from '../Common/molecules/PDFViewer/PDFViewer';
import { selectUser } from '../../modules/login';

import {
  selectAnnotationColor,
  AnnotatedPnIDOverview,
} from './AnnotatedPnIDOverview';

const OverviewWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 24px;
  z-index: 1000;
  padding-top: 24px;
  padding-bottom: 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
  pointer-events: none;

  && > * {
    margin-bottom: 24px;
  }
`;

const CenteredPlaceholder = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;
  text-align: center;
`;

const Buttons = styled.div`
  position: absolute;
  right: 24px;
  top: 24px;
  z-index: 1000;

  && > * {
    margin-right: 6px;
  }
  && > *:nth-last-child(1) {
    margin-right: 0px;
  }
`;

const Wrapper = styled.div`
  flex: 1;
  min-height: 200px;
  height: 100%;
  width: 100%;
  position: relative;
`;

type Props = {
  fileId?: number;
  page?: number;
  children?: React.ReactNode;
  onFileClicked?: (file: FilesMetadata) => void;
  onAssetClicked?: (asset: Asset) => void;
  renderResourceActions?: RenderResourceActionsFunction;
};

export interface ProposedCogniteAnnotation extends PendingCogniteAnnotation {
  id: string;
}

const visibleSimilarityJobs: { [key: string]: boolean } = {};

export const AnnotatedPnIDPreview = ({
  fileId,
  children,
  onFileClicked,
  onAssetClicked,
  renderResourceActions,
  page,
}: Props) => {
  const { search } = useLocation();

  const {
    assetId: selectedAssetId,
    fileId: selectedFileId,
  } = queryString.parse(search, {
    parseNumbers: true,
  });

  const { sdk } = useSelector((state: RootState) => state.app);

  const history = useHistory();
  const dispatch = useDispatch();
  const assetsMap = useSelector(assetSelector);
  const filesMap = useSelector(fileSelector);
  const user = useSelector(selectUser);
  const file = filesMap(fileId);
  const pnidAnnotations = useSelector(selectAnnotations)(fileId);
  const [pendingPnidAnnotations, setPendingPnidAnnotations] = useState(
    [] as ProposedCogniteAnnotation[]
  );

  const [editable, setEditable] = useState(false);
  const [selectedAnnotation, setSelectedAnnotation] = useState<
    ProposedCogniteAnnotation | CogniteAnnotation | undefined
  >(undefined);

  const similarObjectJobs = useSelector((state: RootState) =>
    fileId ? state.fileContextualization.similarObjectJobs[fileId] : {}
  );

  const isFindingSimilarObjects = similarObjectJobs
    ? Object.values(similarObjectJobs).some(el => !el.jobDone)
    : false;

  const annotations = pnidAnnotations
    .map(el => {
      const currentAsset =
        el.resourceType === 'asset' &&
        assetsMap(el.resourceId || el.resourceExternalId);
      const currentFile =
        el.resourceType === 'file' &&
        filesMap(el.resourceId || el.resourceExternalId);
      return {
        id: `${el.id}`,
        comment: el.label || 'No Label',
        page: el.page,
        mark: {
          type: 'RECT',
          x: el.box.xMin,
          y: el.box.yMin,
          width: el.box.xMax - el.box.xMin,
          height: el.box.yMax - el.box.yMin,
          strokeWidth: 2,
          strokeColor: selectAnnotationColor(
            el,
            (!!currentAsset && currentAsset.id === selectedAssetId) ||
              (!!currentFile && currentFile.id === selectedFileId)
          ),
        },
      } as IAnnotation<IRectShapeData>;
    })
    .concat(
      pendingPnidAnnotations.map(
        el =>
          ({
            id: el.id,
            comment: el.label || 'Pending Annotation',
            page: el.page,
            mark: {
              type: 'RECT',
              x: el.box.xMin,
              y: el.box.yMin,
              width: el.box.xMax - el.box.xMin,
              height: el.box.yMax - el.box.yMin,
              strokeColor: 'yellow',
            },
          } as IAnnotation<IRectShapeData>)
      )
    );

  useEffect(() => {
    const assetIds = pnidAnnotations.reduce(
      (prev: Set<number>, el: CogniteAnnotation) => {
        if (el.resourceType === 'asset' && el.resourceId) {
          prev.add(el.resourceId);
        }
        return prev;
      },
      new Set<number>()
    );
    const assetExternalIds = pnidAnnotations.reduce(
      (prev: Set<string>, el: CogniteAnnotation) => {
        if (el.resourceType === 'asset' && el.resourceExternalId) {
          prev.add(el.resourceExternalId);
        }
        return prev;
      },
      new Set<string>()
    );
    dispatch(retrieveAssets([...[...assetIds].map(id => ({ id }))]));
    dispatch(
      retrieveExternalAssets([
        ...[...assetExternalIds].map(id => ({ externalId: id })),
      ])
    );
  }, [dispatch, pnidAnnotations]);

  useEffect(() => {
    const newItems: ProposedCogniteAnnotation[] = [];
    Object.keys(similarObjectJobs || {}).forEach(key => {
      if (!visibleSimilarityJobs[key] && similarObjectJobs[key].annotations) {
        similarObjectJobs[key].annotations!.forEach(el => {
          newItems.push({
            id: uuid(),
            box: el.boundingBox,
            version: CURRENT_VERSION,
            fileId,
            label: '',
            source: `job:${key}`,
            owner: `similar_job:${key}`,
            status: 'unhandled',
            metadata: {
              fromSimilarObject: 'true',
              score: `${el.score}`,
              originalBoxJson: key,
            },
          });
        });
        visibleSimilarityJobs[key] = true;
      }
    });
    if (newItems.length > 0) {
      setPendingPnidAnnotations([...pendingPnidAnnotations, ...newItems]);
    }
  }, [similarObjectJobs, fileId, pendingPnidAnnotations]);

  const onSaveDetection = async (
    pendingAnnotation: ProposedCogniteAnnotation | CogniteAnnotation
  ) => {
    if (!canEditEvents(true)) {
      return;
    }

    if (pendingPnidAnnotations.find(el => el.id === pendingAnnotation.id)) {
      trackUsage('Contextualization.PnidViewer.CreateAnnotation', {
        annotation: pendingAnnotation,
      });
      const pendingObj = { ...pendingAnnotation };
      delete pendingObj.id;
      delete pendingObj.metadata;
      dispatch(createAnnotations(file!, [pendingObj]));
      setPendingPnidAnnotations(
        pendingPnidAnnotations.filter(el => el.id !== pendingAnnotation.id)
      );
    } else {
      message.info('Coming Soon');
    }

    // load missing asset information
    if (
      pendingAnnotation.resourceType === 'asset' &&
      (pendingAnnotation.resourceExternalId || pendingAnnotation.resourceId)
    ) {
      const action = pendingAnnotation.resourceId
        ? retrieveAssets([{ id: pendingAnnotation.resourceId! }])
        : retrieveExternalAssets([
            { externalId: pendingAnnotation.resourceExternalId! },
          ]);
      dispatch(action);
    }
  };

  const onDeleteAnnotation = async (annotation: IAnnotation) => {
    if (!canEditEvents(true)) {
      return;
    }

    if (pendingPnidAnnotations.find(el => el.id === annotation.id)) {
      setPendingPnidAnnotations(
        pendingPnidAnnotations.filter(el => el.id !== annotation.id)
      );
    } else {
      trackUsage('Contextualization.PnidViewer.DeleteAnnotation', {
        annotation,
      });
      const pnidIndex = pnidAnnotations.findIndex(
        el => `${el.id}` === annotation.id
      );
      if (pnidIndex > -1) {
        dispatch(removeAnnotations(file!, [pnidAnnotations[pnidIndex]]));
      }
    }
  };

  const onUpdateAnnotation = async (
    annotation: IAnnotation<IRectShapeData>
  ) => {
    if (!canEditEvents(true)) {
      return;
    }

    if (pendingPnidAnnotations.find(el => el.id === annotation.id)) {
      setPendingPnidAnnotations(
        pendingPnidAnnotations.reduce(
          (prev: ProposedCogniteAnnotation[], el) => {
            if (el.id !== annotation.id) {
              prev.push(el);
            } else {
              prev.push({
                ...el,
                page: el.page,
                box: {
                  xMin: annotation.mark.x,
                  yMin: annotation.mark.y,
                  xMax: annotation.mark.x + annotation.mark.width,
                  yMax: annotation.mark.y + annotation.mark.height,
                },
              });
            }
            return prev;
          },
          [] as ProposedCogniteAnnotation[]
        )
      );
    } else {
      // message.info('Coming Soon');
    }
  };

  const onCreateAnnotation = async (annotation: IAnnotationWithPage) => {
    if (!canEditEvents(true) || !fileId) {
      return;
    }
    trackUsage('Contextualization.PnidViewer.LocalCreateAnnotation', {
      annotation,
    });
    setPendingPnidAnnotations(
      pendingPnidAnnotations
        .filter(el => el.label.length > 0)
        .concat([
          {
            id: annotation.id,
            status: 'verified',
            ...(file!.externalId
              ? { fileExternalId: file!.externalId }
              : { fileId: file!.id }),
            version: CURRENT_VERSION,
            source: `email:${user}`, // TODO add proper user
            label: '',
            page: annotation.page,
            box: {
              xMin: annotation.mark.x,
              yMin: annotation.mark.y,
              xMax: annotation.mark.x + annotation.mark.width,
              yMax: annotation.mark.y + annotation.mark.height,
            },
          },
        ])
    );
  };

  useEffect(() => {
    (async () => {
      if (selectedAnnotation) {
        const {
          resourceId,
          resourceExternalId,
          resourceType,
        } = selectedAnnotation;
        if (resourceType === 'asset') {
          await dispatch(
            retrieveAssets([
              resourceExternalId
                ? { externalId: resourceExternalId }
                : { id: resourceId! },
            ])
          );
          const asset = assetsMap(resourceId || resourceExternalId);
          if (asset) {
            await dispatch(
              onResourceSelected(
                { assetId: asset!.id },
                history,
                undefined,
                true
              )
            );
          }
        }
        if (resourceType === 'file') {
          await dispatch(
            retrieveFiles([
              resourceExternalId
                ? { externalId: resourceExternalId }
                : { id: resourceId! },
            ])
          );
          const selectedFile = filesMap(resourceId || resourceExternalId);
          if (selectedFile) {
            await dispatch(
              onResourceSelected(
                { fileId: selectedFile!.id },
                history,
                undefined,
                true
              )
            );
          }
        }
      }
    })();
  }, [dispatch, selectedAnnotation, assetsMap, filesMap, history]);

  const renderMenuItems = (
    annotation: ProposedCogniteAnnotation | CogniteAnnotation
  ) => {
    return [
      <Menu.Item
        key="find-similar-button"
        appendIcon={isFindingSimilarObjects ? 'Loading' : 'Scan'}
        onClick={async () => {
          if (fileId && !isFindingSimilarObjects) {
            dispatch(findSimilarObjects(fileId, annotation.box));
          }
        }}
      >
        Find Similar Tags
      </Menu.Item>,
    ];
  };
  const renderExtraContent = (
    annotation: ProposedCogniteAnnotation | CogniteAnnotation
  ) => {
    if ('metadata' in annotation) {
      const { score, fromSimilarJob } = annotation.metadata!;
      if (fromSimilarJob) {
        return (
          <div style={{ paddingLeft: '16px', paddingRight: '16px' }}>
            <SmallTitle>From Similar Object</SmallTitle>
            <p>Score: {Math.round((Number(score) + Number.EPSILON) * 100)}%</p>
          </div>
        );
      }
    }
    return null;
  };
  return (
    <Wrapper>
      <Buttons>
        <Button
          type="primary"
          icon={editable ? 'Check' : 'Edit'}
          onClick={() => {
            if (!canEditEvents(true)) {
              return;
            }
            if (editable) {
              if (pendingPnidAnnotations.length > 0) {
                Modal.confirm({
                  title: 'Are you sure?',
                  content: (
                    <span>
                      Do you want to stop editing? You have pending changes,
                      which will be <strong>deleted</strong> if you leave the
                      editing mode now. Of course, any changes you have already
                      written to CDF have been saved.
                    </span>
                  ),
                  onOk: () => {
                    setEditable(!editable);
                    setPendingPnidAnnotations([]);
                  },
                  onCancel: () => {},
                });
                return;
              }
            }
            setEditable(!editable);
          }}
        >
          {editable ? 'Finish Editing' : 'Edit Linkages'}
        </Button>
      </Buttons>
      <OverviewWrapper className="overview">
        {children}
        {fileId && (
          <AnnotatedPnIDOverview fileId={fileId} annotations={annotations} />
        )}
      </OverviewWrapper>
      {file ? (
        <PDFViewer
          file={file}
          sdk={sdk}
          selectedPage={page}
          annotations={annotations}
          drawLabel={false}
          editCallbacks={{
            onDelete: () => {},
            onCreate: onCreateAnnotation,
            onUpdate: onUpdateAnnotation,
          }}
          editable={editable}
          onSelect={annotation => {
            if (annotation) {
              const pnidAnnotation =
                pnidAnnotations.find(el => `${el.id}` === annotation.id) ||
                pendingPnidAnnotations.find(el => el.id === annotation.id);
              if (pnidAnnotation) {
                setSelectedAnnotation(pnidAnnotation);
              }
            } else {
              setSelectedAnnotation(undefined);
              dispatch(onResourceSelected({}, history, undefined, true));
            }
          }}
          renderItemPreview={(
            _: boolean,
            annotation: IAnnotation,
            onLabelChange: (value: string) => void,
            onDelete: () => void
          ) => {
            const pnidAnnotation =
              pnidAnnotations.find(el => `${el.id}` === annotation.id) ||
              pendingPnidAnnotations.find(el => el.id === annotation.id);
            if (pnidAnnotation) {
              return (
                <AnnotatedPnIDItemEditor
                  onFileClicked={onFileClicked}
                  onAssetClicked={onAssetClicked}
                  editable={editable}
                  annotation={pnidAnnotation}
                  onUpdateDetection={async newAnnotation => {
                    onLabelChange(newAnnotation.label || 'No Label');
                    await onSaveDetection(newAnnotation);
                  }}
                  renderResourceActions={renderResourceActions}
                  onDeleteDetection={() => {
                    Modal.confirm({
                      title: 'Are you sure?',
                      content: 'Are you sure you want to delete this linkage?',
                      onOk: () => {
                        onDelete();
                        onDeleteAnnotation(annotation);
                      },
                    });
                  }}
                  extraOverflowMenuItems={renderMenuItems(pnidAnnotation)}
                >
                  {renderExtraContent(pnidAnnotation)}
                </AnnotatedPnIDItemEditor>
              );
            }
            return <></>;
          }}
        />
      ) : (
        <CenteredPlaceholder>
          <h1>No P&ID Selected</h1>
          <p>Please search for a P&ID to start viewing.</p>
        </CenteredPlaceholder>
      )}
    </Wrapper>
  );
};
