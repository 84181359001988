import React from 'react';
import { Progress, Typography } from 'antd';
import { dataKitItemsSelector } from 'modules/selection';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';

const { Text } = Typography;

type Props = {
  id: string;
};
export default function DataKitLoadingProgress(props: Props) {
  const { id } = props;
  const { type } = useSelector((state: RootState) => state.selection.items[id]);

  const { items, progress } = useSelector(dataKitItemsSelector)(id, true);
  const itemCount = items.length;
  const percent: number = progress * 100;

  return (
    <>
      <Text strong>Loading {type}</Text>
      <br />
      <Text type="secondary">{itemCount}</Text>
      <Progress
        percent={percent}
        status={percent === 100 ? 'success' : 'active'}
      />
    </>
  );
}
