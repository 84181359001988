import React, { useEffect, useState } from 'react';
import {
  Table,
  Badge,
  Spin,
  message,
  Dropdown,
  Menu,
  Row,
  Col,
  Tooltip,
  Popover,
} from 'antd';
import { Button, Icon, Colors } from '@cognite/cogs.js';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { startDocumentParsingPipeline } from 'modules/fileContextualization/documentPipeline';
import { RootState } from 'reducers/index';
import {
  linkFileWithAssetsFromAnnotations,
  selectAnnotationsForSource,
  selectAnnotations,
} from 'modules/annotations';
import { FilesMetadata } from '@cognite/sdk';
import { ClickParam } from 'antd/lib/menu';
import { dataKitItemsSelector } from 'modules/selection';
import { Result } from 'modules/sdk-builder/types';
import { canEditFiles } from 'utils/PermissionUtils';
import queryString from 'query-string';
import {
  getPnIdAnnotationCategories,
  selectAnnotationColor,
} from 'components/PnIDPreview/AnnotatedPnIDOverview';
import { UploadJobState } from 'modules/fileContextualization/uploadJobs';
import { ParsingJobState } from 'modules/fileContextualization/parsingJobs';
import LoadResources from './LoadResources';

export default function DocumentContextualize() {
  const { tenant, assetsDataKitId, filesDataKitId } = useParams<{
    tenant: string;
    filesDataKitId: string;
    assetsDataKitId: string;
  }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const { page } = queryString.parse(search, { parseNumbers: true });
  const annotationBySourceMap = useSelector(selectAnnotationsForSource);
  const annotationsMap = useSelector(selectAnnotations);

  const [assetDataKit, fileDataKit] = useSelector((state: RootState) => [
    state.selection.items[assetsDataKitId],
    state.selection.items[filesDataKitId],
  ]);

  useEffect(() => {
    if (!assetDataKit || !fileDataKit) {
      message.error('Invalid Data Selections...');
      history.push(`/${tenant}/document_contextualize`);
    }
  }, [dispatch, history, tenant, assetDataKit, fileDataKit]);

  const { items: files } = useSelector(dataKitItemsSelector)(
    filesDataKitId,
    true,
    true
  ) as Result<FilesMetadata>;

  const { parsingJobs, uploadJobs } = useSelector(
    (state: RootState) => state.fileContextualization
  );

  const isLoading = Object.values(uploadJobs).some(job => !job.jobDone);

  const [selectedKeys, setSelectedKeys] = useState([] as number[]);

  useEffect(() => {
    dispatch(startDocumentParsingPipeline(filesDataKitId, assetsDataKitId));
  }, [dispatch, assetsDataKitId, filesDataKitId]);

  const handleMenuClick = (e: ClickParam, file: FilesMetadata) => {
    switch (e.key) {
      case 'link':
        if (canEditFiles(true)) {
          dispatch(linkFileWithAssetsFromAnnotations(file.id));
        }
    }
  };

  const startUploadJob = () => {
    if (canEditFiles(true)) {
      selectedKeys.forEach(key => {
        const file = files.find(el => el.id === key);
        if (file) {
          message.error(`${file.name} has no annotations`);
        } else {
          message.error(`we are still loading file ${key}`);
        }
      });
    }
  };

  const rows = files
    .filter(el => !!el)
    .map(file => {
      return {
        ...file,
        parsingJob: parsingJobs[file.id],
        uploadJob: uploadJobs[file.id],
      } as FilesMetadata & {
        parsingJob?: ParsingJobState;
        uploadJob?: UploadJobState;
      };
    });

  return (
    <>
      <Row type="flex" align="middle" justify="space-between">
        <Col>
          <h1>Document contextualization results</h1>
        </Col>
        <Col>
          <Tooltip
            placement="left"
            arrowPointAtCenter
            title="This will create or update an interactive SVG linked to the assets for the selected files."
          >
            <Icon
              type="Help"
              style={{
                marginRight: '24px',
                fontSize: '18px',
                display: 'inline-block',
              }}
            />
          </Tooltip>
          <Button
            type="primary"
            disabled={selectedKeys.length === 0}
            onClick={startUploadJob}
            loading={isLoading}
          >
            {`Deploy ${selectedKeys.length} files to CDF`}
          </Button>
        </Col>
      </Row>
      <Row gutter={[0, 20]}>
        <Col span={24}>
          <LoadResources
            assetDataKitId={assetsDataKitId}
            fileDataKitId={filesDataKitId}
          />
        </Col>
      </Row>
      <Row gutter={[0, 20]}>
        <Col span={24}>
          <Table
            rowSelection={{
              onSelectAll: selectAll =>
                setSelectedKeys(
                  selectAll ? rows.map(el => el.id) : ([] as number[])
                ),
              onChange: keys => setSelectedKeys(keys as number[]),
              selectedRowKeys: selectedKeys,
            }}
            columns={[
              { dataIndex: 'name', title: 'Name', key: 'name' },
              {
                title: 'Status',
                key: 'status',
                render: (_, file) => {
                  const { uploadJob, parsingJob } = file;

                  // Error states
                  if (uploadJob && uploadJob.jobError) {
                    return <Badge color="red" text="Failed to Upload" />;
                  }
                  if (parsingJob && parsingJob.jobError) {
                    return (
                      <Badge color="red" text="Failed to parse document" />
                    );
                  }

                  // Regular statuses
                  if (uploadJob) {
                    if (uploadJob.jobDone) {
                      return <Badge color="green" text="Completed" />;
                    }
                    return <Badge color="blue" text="Uploading" />;
                  }
                  if (parsingJob) {
                    if (parsingJob.jobDone) {
                      return <Badge color="green" text="Finished Parsing" />;
                    }
                    return <Badge color="yellow" text="Parsing document..." />;
                  }
                  return <Badge color="grey" text="Pending" />;
                },
              },
              {
                title: 'New Assets Detected',
                key: 'annotations_new',
                render: (_, file) => {
                  const { parsingJob } = file;
                  if (!parsingJob || !parsingJob.jobDone) {
                    return <Spin size="small" />;
                  }
                  const annotations = annotationBySourceMap(
                    file.id,
                    `job:${parsingJob.jobId}`
                  );

                  const newResourceDetected = annotations.filter(
                    el => !!el.resourceType
                  ).length;

                  return (
                    <Popover
                      title="New Assets Detected"
                      placement="bottomLeft"
                      content={
                        <>
                          <p>
                            <Badge
                              style={{
                                backgroundColor: Colors['purple-3'].hex(),
                              }}
                              count={newResourceDetected}
                              showZero
                            />{' '}
                            New Detected Resources Tags
                          </p>
                        </>
                      }
                    >
                      <span>
                        <Badge
                          style={{ backgroundColor: Colors.midblue.hex() }}
                          count={annotations.length}
                          showZero
                        />{' '}
                        New Tags
                      </span>
                    </Popover>
                  );
                },
              },
              {
                title: 'Total Assets Detected',
                key: 'annotations',
                render: (file: FilesMetadata) => {
                  const annotations = annotationsMap(file.id);
                  if (!annotations) {
                    return <Spin size="small" />;
                  }

                  const annotationDetails = getPnIdAnnotationCategories(
                    annotations
                  );

                  const {
                    Asset: { count: assetCount },
                  } = annotationDetails;

                  return (
                    <Popover
                      title="Total Assets Detected"
                      placement="bottomLeft"
                      content={
                        <>
                          {Object.keys(annotationDetails).map(key => {
                            // TODO remove file and unclassified assets
                            const { count, items } = annotationDetails[key];
                            return (
                              <div key={key} style={{ marginBottom: '12px' }}>
                                <strong>
                                  {count} {key} Assets
                                </strong>
                                {Object.keys(items).map(subKey => (
                                  <Row
                                    key={subKey}
                                    type="flex"
                                    align="middle"
                                    style={{ marginBottom: '4px' }}
                                  >
                                    <Col span={16}>{subKey}</Col>
                                    <Col
                                      span={8}
                                      style={{
                                        float: 'right',
                                        display: 'inline-flex',
                                        justifyContent: 'flex-end',
                                      }}
                                    >
                                      <Badge
                                        style={{
                                          backgroundColor: selectAnnotationColor(
                                            items[subKey][0]
                                          ),
                                        }}
                                        count={items[subKey].length}
                                        showZero
                                      />
                                    </Col>
                                  </Row>
                                ))}
                              </div>
                            );
                          })}
                        </>
                      }
                    >
                      <div>
                        <span style={{ marginLeft: '12px' }}>
                          <Badge
                            style={{
                              backgroundColor: Colors['midorange-3'].hex(),
                            }}
                            count={assetCount}
                            showZero
                          />{' '}
                          Asset Tags
                        </span>
                      </div>
                    </Popover>
                  );
                },
              },
              {
                title: 'Actions',
                key: 'actions',
                render: file => {
                  return (
                    <>
                      <Button
                        icon="ArrowRight"
                        onClick={() => {
                          history.push(
                            `/${tenant}/document_contextualize/${filesDataKitId}/${assetsDataKitId}/document/${file.id}`
                          );
                        }}
                      >
                        View
                      </Button>
                      <Dropdown
                        overlay={
                          <Menu onClick={e => handleMenuClick(e, file)}>
                            <Menu.Item key="link">
                              Link Assets to File
                            </Menu.Item>
                          </Menu>
                        }
                      >
                        <Button
                          style={{
                            marginLeft: '8px',
                            paddingLeft: '8px',
                            paddingRight: '8px',
                            width: 'auto',
                            minWidth: 'auto',
                          }}
                          disabled={
                            !(
                              file &&
                              file.annotations &&
                              file.annotations.length > 0
                            )
                          }
                          icon="HorizontalEllipsis"
                        />
                      </Dropdown>
                    </>
                  );
                },
              },
            ]}
            dataSource={rows}
            rowKey="id"
            pagination={{
              onChange: newPage => {
                history.push({
                  search: queryString.stringify({
                    ...queryString.parse(search),
                    page: newPage,
                  }),
                });
              },
              current: (page || 0) as number,
            }}
          />
        </Col>
      </Row>
    </>
  );
}
