import React from 'react';
import { IAnnotation, IRectShapeData } from '@cognite/react-picture-annotation';
import styled from 'styled-components';
import { itemSelector as assetSelector } from 'modules/assets';
import { useSelector } from 'react-redux';
import { selectAnnotations } from 'modules/annotations';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { itemSelector as fileSelector } from 'modules/files';
import { PDFViewer } from 'components/Common';
import { RootState } from 'reducers';
import { selectAnnotationColor } from './AnnotatedPnIDOverview';

const Wrapper = styled.div`
  flex: 1;
  min-height: 200px;
  height: 100%;
  width: 100%;
  position: relative;
`;

type Props = {
  fileId?: number;
  children?: React.ReactNode;
};

export const AnnotatedPnIDPreviewImage = ({ fileId }: Props) => {
  const { search } = useLocation();

  const { sdk } = useSelector((state: RootState) => state.app);

  const {
    assetId: selectedAssetId,
    fileId: selectedFileId,
  } = queryString.parse(search, {
    parseNumbers: true,
  });

  const assetsMap = useSelector(assetSelector);
  const filesMap = useSelector(fileSelector);
  const pnidAnnotations = useSelector(selectAnnotations)(fileId);

  const annotations = pnidAnnotations.map(el => {
    const currentAsset = assetsMap(
      el.resourceType === 'asset'
        ? el.resourceId || el.resourceExternalId
        : undefined
    );
    const currentFile = filesMap(
      el.resourceType === 'file'
        ? el.resourceId || el.resourceExternalId
        : undefined
    );
    return {
      id: `${el.id}`,
      comment: el.label || 'No Label',
      mark: {
        type: 'RECT',
        x: el.box.xMin,
        y: el.box.yMin,
        width: el.box.xMax - el.box.xMin,
        height: el.box.yMax - el.box.yMin,
        strokeWidth: 2,
        strokeColor: selectAnnotationColor(
          el,
          (!!currentAsset && currentAsset.id === selectedAssetId) ||
            (!!currentFile && currentFile.id === selectedFileId)
        ),
      },
    } as IAnnotation<IRectShapeData>;
  });

  return (
    <Wrapper>
      <PDFViewer
        sdk={sdk}
        file={filesMap(fileId)}
        annotations={annotations}
        drawLabel={false}
        hidePagination
        renderItemPreview={() => <></>}
      />
    </Wrapper>
  );
};
