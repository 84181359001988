import React from 'react';
import styled from 'styled-components';
import { Badge } from 'antd';
import { Colors, Button } from '@cognite/cogs.js';

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 16px;
  box-shadow: 0px 0px 6px #cdcdcd;
  border-radius: 12px;
  box-sizing: border-box;
  z-index: 1;
  background: #fff;
  width: 400px;
  height: auto;
  max-height: 100%;
  margin-right: 20px;
  overflow: auto;
  h1 {
    margin-bottom: 0px;
    flex: 1;
  }
`;

const SidebarHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-bottom: 16px;
  border-bottom: 1px solid #aaa;
`;

const HeaderText = styled.h1`
  font-weight: 800;
  font-size: 1.1em;
  text-transform: uppercase;
`;

const StyledResourceList = styled.ul`
  list-style-type: none;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  width: 100%;

  li {
    padding: 16px 0;
    border-bottom: 1px solid #aaa;
    width: 100%;
  }

  li p {
    text-transform: uppercase;
    font-size: 0.8em;
    font-weight: 600;
    letter-spacing: 0.04em;
  }
`;

type TPropsResourceBar = {
  annotations: any[];
  annotationsCount: number;
  onPageChange: Function;
};

export default function FileDetailSidebar({
  annotations,
  annotationsCount,
  onPageChange,
}: TPropsResourceBar) {
  const disabled = true;

  const selectPage = (page: string) => {
    const selectedPage = parseInt(page, 10);
    onPageChange(selectedPage);
  };

  const preparedPages = (annotationLabel: string) => {
    const annotation =
      // TODO remove the hack
      // eslint-disable-next-line react/prop-types
      annotations && annotations.find((a: any) => a.label === annotationLabel);
    const annotationPages = annotation ? annotation.pages : undefined;

    if (annotationPages) {
      return Object.keys(annotationPages).map(page => (
        <Button
          key={`${annotationLabel.replace(' ', '')}_${page}`}
          onClick={() => selectPage(page)}
          style={{
            padding: '10px',
            width: '32px',
            height: '32px',
            margin: '0 4px',
          }}
        >
          {Number(page) + 1}
        </Button>
      ));
    }
    return (
      <Button
        disabled={disabled}
        style={{
          padding: '10px',
          width: '32px',
          height: '32px',
          margin: '0 4px',
          // TODO this is fixed in 1.1.4 cogs.js which is not released on yarn yet
          cursor: disabled ? 'not-allowed' : 'pointer',
        }}
        icon="Minus"
      />
    );
  };

  const annotationList = annotations
    ? [...new Set(annotations.map((annotation: any) => annotation.label))]
        .sort()
        .map((annotation: any) => (
          <li key={annotation}>
            <h3>{annotation}</h3>
            <p>Located on pages</p>
            {preparedPages(annotation)}
          </li>
        ))
    : [];

  return (
    <Sidebar>
      <SidebarHeader>
        <HeaderText>Detected assets</HeaderText>
        <span style={{ marginLeft: '12px' }}>
          <Badge
            style={{ backgroundColor: Colors.midblue.hex() }}
            count={annotationsCount}
            showZero
          />{' '}
        </span>
      </SidebarHeader>
      <StyledResourceList>{annotationList}</StyledResourceList>
    </Sidebar>
  );
}
