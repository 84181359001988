import React, { useState, useEffect } from 'react';
import { Select, Col, Row, Spin } from 'antd';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { Asset } from '@cognite/sdk';
import { Button } from '@cognite/cogs.js';
import {
  search,
  itemSelector as assetSelector,
  searchSelector as searchAssetSelector,
} from 'modules/assets';
import { stageChange, Prediction } from 'modules/contextualization/predictions';
import { trackUsage } from 'utils/Metrics';

const Wrapper = styled.div`
  .ant-select-selection {
    height: 64px;
  }
  .ant-select-selection--single {
    height: 64px;
  }
`;

const CONFIDENCE_CUTTOF = 0.5;

export default function AssetSearchWithRec(props: {
  prediction: Prediction;
  resourceDataKitId: string;
}) {
  const dispatch = useDispatch();
  const { prediction, resourceDataKitId } = props;

  const [query, setQuery] = useState('');

  const searchQuery = (q: string) => ({
    limit: 10,
    search: q.length > 0 ? { query: q } : {},
  });

  const getAsset = useSelector(assetSelector);

  const recommendedAssets =
    prediction.matches[0]?.score >= CONFIDENCE_CUTTOF &&
    prediction.matches[0]?.matchTo.id
      ? [getAsset(prediction.matches[0]?.matchTo.id)]
      : [];

  const [selectedAsset, setSelectedAsset] = useState<Asset | undefined>(
    recommendedAssets[0]
  );

  const { items: searchAssets, fetching } = useSelector(searchAssetSelector)(
    searchQuery(query)
  );

  useEffect(() => {
    if (query.length > 0) {
      const q = searchQuery(query);
      dispatch(search(q));
      trackUsage('Contextualization.ResourceMatching.Manual.AssetSearch', {
        query: q,
        dataSetId: resourceDataKitId,
      });
    }
  }, [dispatch, query, resourceDataKitId]);

  return (
    <Wrapper>
      <Row key={prediction.matchFrom.id}>
        <Col span={16}>
          <Select
            showSearch
            placeholder="Search for asset"
            style={{ width: '100%' }}
            optionFilterProp="children"
            value={(selectedAsset || {}).id}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            onSearch={setQuery}
            onSelect={(assetId: number) => {
              const asset = [...recommendedAssets, ...searchAssets].find(
                a => a?.id === assetId
              );
              trackUsage(
                'Contextualization.ResourceMatching.Manual.AssetSelect',
                {
                  assetName: asset ? asset.name : undefined,
                  dataSetId: resourceDataKitId,
                }
              );
              setSelectedAsset(asset);
            }}
          >
            <Select.OptGroup label="Recommendation" key="rec">
              {recommendedAssets.map(asset => (
                <Select.Option
                  key={asset?.id}
                  value={asset?.id}
                  title={asset?.name}
                >
                  <b>{asset?.name}</b>
                  <br />
                  {asset?.description}
                </Select.Option>
              ))}
            </Select.OptGroup>
            <Select.OptGroup label="Search result" key="Search">
              {query.length > 0
                ? searchAssets.map(asset => (
                    <Select.Option
                      key={asset?.id}
                      value={asset?.id}
                      title={asset?.name}
                    >
                      {asset?.name}
                    </Select.Option>
                  ))
                : undefined}
            </Select.OptGroup>
          </Select>
        </Col>

        <Col span={8} style={{ textAlign: 'center' }}>
          <Button
            icon="Check"
            disabled={!selectedAsset}
            onClick={event => {
              event.preventDefault();
              if (selectedAsset) {
                trackUsage('Contextualization.ResourceMatching.Manual.Apply', {
                  resourceName: prediction.matchFrom.name || '',
                  assetName: selectedAsset.name,
                  dataSetId: resourceDataKitId,
                });
                dispatch(
                  stageChange(
                    {
                      resourceId: prediction.matchFrom.id,
                      assetId: selectedAsset.id,
                      rule: 'Manual Matching',
                    },
                    resourceDataKitId
                  )
                );
              }
            }}
          />
        </Col>
      </Row>
    </Wrapper>
  );
}
