import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Button, Icon } from '@cognite/cogs.js';
import {
  list as listAnnotations,
  selectAnnotations,
} from 'modules/annotations';
import { itemSelector as fileItemSelector, retrieve } from 'modules/files';
import { CogniteAnnotation } from '@cognite/annotations';
import { AnnotatedPnIDPreview } from 'components/PnIDPreview/AnnotatedPnIDPreview';
import { ResourceSidebar } from 'containers/ResourceSidebar';
import FileDetailSidebar from './FileDetailSidebar';

const Wrapper = styled.div`
  display: flex;
  height: calc(100vh - 180px);
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  padding: 20px 24px;
  box-shadow: 0px 0px 6px #cdcdcd;
  border-radius: 12px;
  flex-direction: row;
  align-items: center;
  z-index: 1;
  margin-top: 40px;
  background: #fff;
  button {
    margin-right: 26px;
  }
  h1 {
    margin-bottom: 0px;
    flex: 1;
  }
`;

const HeaderText = styled.h1`
  font-weight: 800;
  font-size: 1.1em;
  text-transform: uppercase;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  margin-top: 20px;
  box-sizing: border-box;
`;

const PDFWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const BlockerAssetsUnloaded = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  filter: blur(3px);
`;

export default function DocumentContextualizeFilePreview() {
  const { fileId, tenant, filesDataKitId, assetsDataKitId } = useParams<{
    fileId: string;
    tenant: string;
    filesDataKitId: string;
    assetsDataKitId: string;
  }>();
  const history = useHistory();
  const dispatch = useDispatch();

  const [annotationsLocated, setAnnotationsLocated] = useState(false);
  const [annotationsLoading, setAnnotationsLoading] = useState(false);
  const [locatedAnnotations, setLocatedAnnotations] = useState([] as any);
  const [selectedPage, setSelectedPage] = useState(0);
  const fileIdNumber = parseInt(fileId, 10);

  const file = useSelector(fileItemSelector)(fileIdNumber);
  const notLocatedAnnotations = useSelector(selectAnnotations)(fileIdNumber);
  const annotationsCount = notLocatedAnnotations.length;

  useEffect(() => {
    if (file) {
      dispatch(listAnnotations(file, false, true));
    }
  }, [dispatch, file]);

  useEffect(() => {
    dispatch(retrieve([{ id: fileIdNumber }]));
  }, [dispatch, fileIdNumber]);

  const handlePageChange = (page: number) => setSelectedPage(page);
  const locateAnnotations = () => {
    const loadedAnnotations: any[] = [];
    notLocatedAnnotations.map((annotation: CogniteAnnotation) => {
      const annotationPage: number | undefined = annotation.page;
      const annotationIndex = loadedAnnotations.findIndex(
        a => a.label === annotation.label
      );
      if (annotationPage === undefined) {
        return undefined;
      }
      if (annotationIndex !== -1) {
        const page = loadedAnnotations[annotationIndex].pages[annotationPage];
        if (page) {
          loadedAnnotations[annotationIndex].pages[annotationPage].push(
            annotation.box
          );
        } else {
          loadedAnnotations[annotationIndex].pages[annotationPage] = [
            annotation.box,
          ];
        }
      } else {
        loadedAnnotations.push({
          label: annotation.label,
          pages: {
            [annotationPage]: [annotation.box],
          },
        });
      }
      return annotation;
    });

    setAnnotationsLoading(false);
    setAnnotationsLocated(true);
    setLocatedAnnotations(loadedAnnotations);
  };

  if (annotationsLoading && !annotationsLocated) {
    locateAnnotations();
  }

  const showPreviewIfLocated = () => {
    return !annotationsLocated ? (
      <>
        <BlockerAssetsUnloaded>
          <AnnotatedPnIDPreview fileId={fileIdNumber} page={selectedPage} />
        </BlockerAssetsUnloaded>
        {loadButtonOrLoading()}
      </>
    ) : (
      <AnnotatedPnIDPreview fileId={fileIdNumber} page={selectedPage} />
    );
  };

  const startAnnotationsLoading = () => {
    setAnnotationsLoading(true);
  };

  const loadButtonOrLoading = () => {
    return !annotationsLoading ? (
      <Button
        type="primary"
        onClick={startAnnotationsLoading}
        style={{ position: 'absolute' }}
      >
        Locate assets in document
      </Button>
    ) : (
      <Icon type="Loading" style={{ position: 'absolute' }} />
    );
  };

  return (
    <Wrapper>
      <Header>
        <HeaderText>{file ? file.name : 'Loading...'}</HeaderText>
        <Button
          shape="round"
          icon="ArrowLeft"
          onClick={() =>
            history.push(
              `/${tenant}/document_contextualize/${filesDataKitId}/${assetsDataKitId}`
            )
          }
        >
          Back
        </Button>
      </Header>
      <ContentWrapper>
        {/* currently only assets, other resource types will be introduced later */}
        <FileDetailSidebar
          annotations={
            annotationsLocated ? locatedAnnotations : notLocatedAnnotations
          }
          annotationsCount={annotationsCount}
          onPageChange={handlePageChange}
        />
        <PDFWrapper>{showPreviewIfLocated()}</PDFWrapper>
      </ContentWrapper>

      <ResourceSidebar />
    </Wrapper>
  );
}
