import { AnyAction, combineReducers } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Asset } from '@cognite/sdk';
import { RootState } from 'reducers';

import { dataKitItemsSelector, loadResourceSelection } from 'modules/selection';
import { Result } from 'modules/sdk-builder/types';
import modelReducer, {
  ItemWithNameAndId,
  entityMatchingFit,
  ensureModel,
  resetModel,
} from './models';
import predictionReducer, {
  predict,
  ensurePrediction,
  resetPredictions,
  Resource,
} from './predictions';
import ruleReducer, { getRules, downloadRules, resetRules } from './rules';

export type ModelType = 'simple' | 'bigram';

export function buildModelGetPredictions(
  assetDataKitId: string,
  resourceDataKitId: string,
  modelType: ModelType,
  properties: any
) {
  return async (
    dispatch: ThunkDispatch<any, any, AnyAction>,
    getState: () => RootState
  ) => {
    const { started: modelStarted, done: modelDone } =
      getState().contextualization.models[assetDataKitId] || {};
    if (modelStarted && !modelDone) {
      throw new Error(
        'Already creating a model, this should not be done in parallel'
      );
    }

    try {
      const trainingDataPromise = dispatch(
        loadResourceSelection(assetDataKitId)
      );
      const predictDataPromise = dispatch(
        loadResourceSelection(resourceDataKitId)
      );

      await predictDataPromise;
      const resourceData = dataKitItemsSelector(getState())(
        resourceDataKitId,
        true
      ) as Result<Resource>;

      const predictData: ItemWithNameAndId[] = resourceData.items
        .filter(resource => !!resource.name)
        .map(resources => ({
          name: resources.name,
          id: resources.id,
        })) as ItemWithNameAndId[];

      if (predictData.length === 0) {
        throw new Error('Training data missing');
      }

      await trainingDataPromise;
      const assetsData = dataKitItemsSelector(getState())(
        assetDataKitId,
        true
      ) as Result<Asset>;

      const trainingDataFrom: any = resourceData.items.map(resource => {
        const data = {
          id: resource.id,
          name: resource.name,
          assetId: resource.assetId,
        };
        properties.from.map((prop: string) => {
          // TODO remove ts-ignores
          // @ts-ignore
          if (resource[prop]) data[prop] = resource[prop];
          // @ts-ignore
          if (resource.metadata && resource.metadata[prop])
            // @ts-ignore
            data[prop] = resource.metadata[prop];
          return true;
        });
        return data;
      });
      const trainingDataTo: any = assetsData.items.map(asset => {
        const data = {
          id: asset.id,
          name: asset.name,
        };
        properties.to.map((prop: string) => {
          // @ts-ignore
          if (asset[prop]) data[prop] = asset[prop];
          if (asset.metadata && asset.metadata[prop])
            // @ts-ignore
            data[prop] = asset.metadata[prop];
          return true;
        });
        return data;
      });

      const modelId: number = await dispatch(
        entityMatchingFit(
          assetDataKitId,
          modelType,
          trainingDataFrom,
          trainingDataTo
        )
      );
      const predictId = await dispatch(predict(modelId, resourceDataKitId));
      const ruleId = await dispatch(getRules(resourceDataKitId));
      return {
        modelId,
        predictId,
        ruleId,
      };
    } catch (e) {
      return {};
      // Each rule handles errors, doesn't stop the exception
    }
  };
}

export function ensureDownloadedData(
  assetDataKitId: string,
  resourceDataKitId: string,
  modelId: string,
  predictId: string,
  ruleId: string
) {
  return async (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    const trainingDataPromise = dispatch(loadResourceSelection(assetDataKitId));
    const predictDataPromise = dispatch(
      loadResourceSelection(resourceDataKitId)
    );

    await Promise.all([trainingDataPromise, predictDataPromise]);

    await dispatch(ensureModel(modelId, assetDataKitId));
    await dispatch(ensurePrediction(modelId, predictId, resourceDataKitId));
    await dispatch(downloadRules(ruleId, resourceDataKitId));
  };
}

export function reset(assetDKId: string, resourceDKId: string) {
  return (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    dispatch(resetModel(assetDKId));
    dispatch(resetPredictions(resourceDKId));
    dispatch(resetRules(resourceDKId));
  };
}

export default combineReducers({
  models: modelReducer,
  predictions: predictionReducer,
  rules: ruleReducer,
});
